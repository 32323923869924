<template>
  <div class="pre-class-pay">
    <el-dialog title="提示" :visible.sync="pay_bol" width="500px" :before-close="closeVisible">
      <div class="code-value">
        <div class="code-img">
          <div id="idCode"></div>
          <div class="code-update" @click="codeUpdate" v-if="code_update_bol" v-loading="code_loading">
            <div>二维码失效，点击重新获取</div>
            <i class="el-icon-refresh-right"></i>
          </div>
        </div>
        <div class="code-text">{{code_update_bol ? "如已支付请手动刷新页面查看！" : "请微信扫码支付"}}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "preClassPay",
  props: {
    pay_code: {
      type: Object,
      default: {},
    },
    pay_bol: {    //弹窗
      type: Boolean,
      default: false,
    },
    code_loading: {   //刷新
      type: Boolean,
      default: false,
    },
  },
  components: {

  },
  data() {
    return {
      code_update_bol: false,//更新
      minuteTime: null,
      minutes: 0,
      seconds: 0,
    };
  },
  watch: {
    pay_code(newV, oldV) {
      this.code_update_bol = false;
      this.openIdCode(newV);
    },
    pay_bol(newV, oldV) {
      if (!newV) {
        clearTimeout(this.minuteTime);
      }
    }
  },
  mounted() {
    
  },
  methods: {
    // 生成二维码
    openIdCode(newV) {
      let url = newV.code_url;
      clearTimeout(this.minuteTime);
      this.$nextTick(() => {
        this.code_update_bol = false;
        if (!this.qrcode) {
          this.qrcode = new QRCode("idCode", {
            text: url,
            width: 160,
            height: 160,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });
        } else {
          this.qrcode.clear();
          this.qrcode.makeCode(url);
        }
        let now_time = this.$moment(new Date()).unix();
        let latency = now_time - newV.create_time;
        let expire = newV.expire_time - newV.create_time;
        let time = expire - latency;
        let times = this.$moment.duration(time, "seconds");
        let min = times.minutes();
        let ss = times.seconds();
        this.minutes = min > 0 ? min : 0;
        this.seconds = ss > 0 ? ss : 0;
        if (latency > expire) {
          this.code_update_bol = true;
          clearTimeout(this.minuteTime);
          this.$emit('codeStop');
        }else {
          this.countDown();
        } 
      });
    },
    // 二维码失效倒计时
    countDown() {
      if (this.pay_bol) {
        this.minuteTime = setTimeout(() => {
          if (this.seconds == 0 && this.minutes != 0) {
            this.seconds = 59;
            this.minutes = this.minutes - 1;
          } else if (this.minutes == 0 && this.seconds == 0) {
            this.seconds = 0;
            clearTimeout(this.minuteTime);
            this.$emit('codeStop');
            this.code_update_bol = true;
            return
          } else {
            this.seconds = this.seconds - 1;
          }
          this.countDown();
        }, 1000);
      }
    },
    // 更新二维码
    codeUpdate(){
      this.$emit('codeUpdate');
    },
    // 关闭弹窗
    closeVisible(done){
      this.$confirm('确认取消支付？').then(_ => {
        this.$emit('closePay');
      }).catch(_ => {});
    },
  },
};
</script>

<style scoped lang="less">
.code-value{
  width: 160px;
  margin: 0 auto;
  .code-text{
    font-size: 16px;
    text-align: center;
    margin-top: 16px;
    color: #e3954f;
  }
  .code-img{
    width: 160px;
    height: 160px;
    position: relative;
    .code-update{
      cursor: pointer;
      width: 160px;
      height: 160px;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      div{
        font-size: 12px;
        color: white;
        margin-top: 30px;
      }
      i{
        color: #e3954f;
        font-size: 50px;
        margin-top: 10px;
      }
      background: rgba(0, 0, 0, 0.7);
    }
  }
}
</style>
