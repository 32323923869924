<template>
  <div class="pre-classification">
    <div class="pre-title" v-show="showTitle">申请预归类</div>
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="left"
      label-width="139px"
      class="demo-ruleForm"
    >
      <el-form-item label="公司全称:" prop="company_name">
        <el-input
          placeholder="输入公司全称"
          :disabled="disabled"
          v-model="form.company_name"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系人:" prop="contact">
        <el-input
          placeholder="输入联系人"
          :disabled="disabled"
          v-model="form.contact"
        ></el-input>
      </el-form-item>
      <el-row>
        <el-col :span="13">
          <el-form-item prop="email" label="Email:">
            <el-input
              placeholder="输入Email"
              :disabled="disabled"
              class="input-width-all"
              v-model="form.email"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item prop="mobile" label="联系电话:" label-width="110px">
            <el-input
              placeholder="输入联系电话"
              :disabled="disabled"
              class="input-width-all"
              v-model="form.mobile"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="13">
          <el-form-item prop="art_no" label="货号:">
            <el-input
              placeholder="输入货号"
              :disabled="disabled"
              class="input-width-all"
              v-model="form.art_no"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item
            prop="specific_model"
            label="规格型号:"
            label-width="110px"
          >
            <el-input
              placeholder="输入规格型号"
              :disabled="disabled"
              class="input-width-all"
              v-model="form.specific_model"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="13">
          <el-form-item prop="product_name_zh" label="中文名称:">
            <el-input
              placeholder="输入中文名称"
              :disabled="disabled"
              class="input-width-all"
              v-model="form.product_name_zh"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item
            prop="product_name_en"
            label="英文名称:"
            label-width="110px"
          >
            <el-input
              placeholder="输入英文名称"
              :disabled="disabled"
              class="input-width-all"
              v-model="form.product_name_en"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="商品描述:" prop="product_desc">
        <el-input
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 3 }"
          placeholder="输入商品描述"
          :disabled="disabled"
          v-model="form.product_desc"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="建议税号:" v-if="!isApproved">
        <el-input
          placeholder="输入建议税号"
          :disabled="disabled"
          v-model="form.recommended_ein"
        ></el-input>
      </el-form-item>
      <el-form-item label="申请单位:">
        <el-input
          placeholder="输入申请单位"
          :disabled="disabled"
          v-model="form.applicant"
        ></el-input>
      </el-form-item>
      <el-form-item label="通讯地址:">
        <el-input
          placeholder="输入通讯地址"
          :disabled="disabled"
          v-model="form.mailing_address"
        ></el-input>
      </el-form-item>
      <el-form-item label="企业信用代码:">
        <el-input
          placeholder="输入企业信用代码"
          :disabled="disabled"
          v-model="form.credit_code"
        ></el-input>
      </el-form-item>
      <el-form-item label="产品图片:">
        <div class="product-picture">
          <fileUpload
            style="float: left"
            @selectFile="selectFile"
            @startUploadOSS="startUploadOSS"
            @uploadCompleteOSS="uploadCompleteOSS"
            ref="uploadComponent"
            accept=".jpg,.jpeg,.bmp,.png"
            :multiple="true"
            v-if="!disabled"
          >
            <div class="product-default">
              <i class="el-icon-plus"></i>
            </div>
          </fileUpload>
          <div class="product-item" v-for="(val, ind) in img_list" :key="ind">
            <el-image
              :fit="'contain'"
              style="width: 99px; height: 99px; background: #f2f2f2"
              :src="val.url"
              :preview-src-list="[val.url]"
            ></el-image>
            <i
              class="el-icon-error"
              v-if="!disabled"
              @click="deleteImg(val, ind)"
            ></i>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <template v-if="!isEdit">
          <el-button @click="submitForm('form')" v-if="!disabled"
            >存为草稿</el-button
          >
        </template>
        <el-button type="primary" @click="resetForm('form')" v-if="!disabled"
          >提交</el-button
        >
      </el-form-item>
    </el-form>
    <div class="examine" v-if="isApproved">
      <el-form
        label-position="left"
        label-width="139px"
        :model="examineFormData"
        :rules="rules"
        ref="examineFormDataRef"
      >
        <el-form-item label="建议税号">
          <el-input
            v-model="examineFormData.recommended_ein"
              :disabled="disabled"
            placeholder="请输入税号"
          ></el-input>
        </el-form-item>
        <el-form-item label="申报要素">
          <el-input
            type="textarea"
            v-model="examineFormData.declare_elements"
            :disabled="disabled"
            :autosize="{ minRows: 4, maxRows: 8 }"
            placeholder="请输入申报要素"
          ></el-input>
        </el-form-item>
        <el-form-item label="归类依据或理由">
          <el-input
            type="textarea"
            v-model="examineFormData.classified_reason"
            :disabled="disabled"
            :autosize="{ minRows: 2, maxRows: 6 }"
            placeholder="请输入归类依据或理由"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <el-button style="display: none" type="primary" @click="reset"
      >重置</el-button
    >
  </div>
</template>

<script>
import { validateTelephone } from "~/baseUtils/validate";
import fileUpload from "@/baseComponents/fileUpload";
export default {
  name: "preClassification",
  props: {
    form_data: {
      type: Object,
      default: {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    img_limit: {
      type: Number,
      default: 10, //图片长度限制
    },
    isApproved:{
      type: Boolean,
      default: false,
    }
  },
  components: {
    fileUpload,
  },
  data() {
    return {
      form: {},
      img_list: [], //图片列表

      filelist: [], //上传数据
      filelist_num: 0, //上传计数
      filelist_bol: true, //是否有上传
      rules: {
        company_name: [{ required: true, message: " ", trigger: "blur" }],
        contact: [{ required: true, message: " ", trigger: "blur" }],
        email: [
          { required: true, message: " ", trigger: "blur" },
          { type: "email", message: " ", trigger: "blur" },
        ],
        mobile: [
          {
            required: true,
            validator: async (rule, value, callback) => {
              if (value == "") {
                callback(" ");
              } else if (!validateTelephone(value)) {
                callback(" ");
              } else {
              }
            },
            trigger: "blur",
          },
        ],
        art_no: [{ required: true, message: " ", trigger: "blur" }],
        specific_model: [{ required: true, message: " ", trigger: "blur" }],
        product_name_zh: [{ required: true, message: " ", trigger: "blur" }],
        product_name_en: [{ required: true, message: " ", trigger: "blur" }],
        product_desc: [{ required: true, message: " ", trigger: "blur" }],
      }, //表单验证
      examineFormData: {
        recommended_ein: "",
        declare_elements: "",
        classified_reason: "",
      },
    };
  },
  watch: {},
  mounted() {
    this.resetFormUpdate();
    if (this.disabled || this.isEdit) {
      let {
        company_name,
        contact,
        email,
        mobile,
        art_no,
        specific_model,
        product_name_zh,
        product_name_en,
        product_desc,
        recommended_ein,
        applicant,
        mailing_address,
        credit_code,
        product_image,
      } = this.form_data;
      this.form = {
        status: 1,
        company_name,
        contact,
        email,
        mobile,
        art_no,
        specific_model,
        product_name_zh,
        product_name_en,
        product_desc,
        recommended_ein,
        applicant,
        mailing_address,
        credit_code,
        product_image: JSON.parse(product_image),
      };
      this.img_list = Object.assign([], this.form.product_image);
    }
    if(this.isApproved){
      this.examineFormData = {
        recommended_ein: this.form_data.recommended_ein,
        declare_elements: this.form_data.declare_elements,
        classified_reason: this.form_data.classified_reason
      }
    }
  },
  methods: {
    resetFormUpdate(){
      this.form = {
        status: 1,
        company_name: this.USER_INFO.company_name_zh || this.USER_INFO.company_name_en,
        contact: this.USER_INFO.name_zh || this.USER_INFO.name_en,
        email: this.USER_INFO.email,
        mobile: this.USER_INFO.mobile,
        art_no: "",
        specific_model: "",
        product_name_zh: "",
        product_name_en: "",
        product_desc: "",
        recommended_ein: "",
        applicant: "",
        mailing_address: "",
        credit_code: "",
        product_image: [],
      }
    },
    // 上传图片
    selectFile(filelist) {
      this.filelist_bol = false;
      if (filelist.length + this.form.product_image.length > this.img_limit) {
        this.$message({
          message: `图片上传限制为${this.img_limit}张，请删除后重新选择图片`,
          type: "warning",
        });
        this.$refs.uploadComponent.fileRemove(filelist[filelist.length - 1]);
        filelist = filelist.splice(this.img_limit - this.form.product_image.length, filelist.length - 1);
        return false;
      }
      this.img_list = this.form.product_image.concat(filelist);
      this.filelist = filelist;
    },
    // 删除图片
    deleteImg(val, ind) {
      if (ind < this.form.product_image.length) {
        this.form.product_image.splice(ind, 1);
      }else{
        this.$refs.uploadComponent.fileRemove(val);
      }
      this.filelist_bol = this.filelist.length == 0;
      this.img_list.splice(ind, 1);
    },
    deleteOssItem(name) {
      //删除阿里云oss文件
      if (!name) return;
      const client = new OSS.Wrapper(this.ossData);
      client.delete(name);
    },
    // 开始上传
    startUploadOSS() {
      this.$emit("startUploadOSS");
    },
    // 上传完成
    uploadCompleteOSS(value) {
      this.filelist_num += 1;
      let img_item = {
        url: value.url,
      };
      this.form.product_image.push(img_item);
      if (this.filelist.length == this.filelist_num) {
        this.filelist_bol = true;
        this.$emit("uploadCompleteOSS", this.form);
        this.filelist_num = 0;
      }
    },
    // 存为草稿
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form.status = 0;
          if (this.filelist_bol) {
            this.$emit("uploadCompleteOSS", this.form);
          } else {
            this.$refs.uploadComponent.uploadFileToOss();
          }
        } else {
          this.$message.warning("请检查输入项");
          return false;
        }
      });
    },
    // 提交
    resetForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form.status = 1;
          if (this.filelist_bol) {
            this.$emit("uploadCompleteOSS", this.form);
          } else {
            this.$refs.uploadComponent.uploadFileToOss();
          }
        } else {
          this.$message.warning("请检查输入项");
          return false;
        }
      });
    },
    // 重置
    reset() {
      let _params = Object.assign({}, this.form);
      Object.keys(_params).forEach((item) => {
        _params[item] = null;
      });
      _params.product_image = [];
      this.filelist.splice(0, this.filelist.length);
      this.img_list = [];
      this.form = _params;
      this.resetFormUpdate();
    },
  },
};
</script>

<style scoped lang="less">
.pre-classification {
  font-size: 16px;
  width: 1200px;
  background: #ffffff;
  padding: 48px 130px 42px;
  /deep/.el-form-item__label {
    font-size: 16px;
  }
  /deep/.el-input__inner {
    font-size: 16px;
  }
  /deep/.el-textarea__inner {
    font-size: 16px;
  }
  .input-width-all{
    width: 320px;
  }
}
.pre-title {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  text-align: center;
  margin-bottom: 30px;
}
.product-default {
  width: 99px;
  height: 99px;
  background: #fef9f5;
  border: 1px dashed #e3954f;
  border-radius: 4px;
  font-size: 32px;
  text-align: center;
  line-height: 97px;
  color: #e3954f;
  margin: 0 18px 18px 0;
}
.product-item {
  position: relative;
  font-size: 20px;
  color: #dddddd;
  width: 99px;
  height: 99px;
  border-radius: 4px;
  overflow: hidden;
  margin: 0 18px 18px 0;
  i {
    position: absolute;
    top: 4px;
    right: 4px;
  }
}
.product-item:nth-child(7n) {
  margin: 0 0 18px 0;
}
.product-picture {
  display: flex;
  flex-wrap: wrap;
}
</style>
